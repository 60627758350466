import { http } from '@/http/axios.js'

// 列表
export function getList(params) {
  return http({
    url: `/company/evaluate/list`,
    method: 'get',
    params
  })
}



export function removeEvaluate(data) {
  return http({
    url: `/company/evaluate/delete`,
    method: 'post',
    data
  })
}
export function addEvaluate(data) {
  return http({
    url: `/company/evaluate/add`,
    method: 'post',
    data
  })
}

export function editEvaluate(data) {
  return http({
    url: `/company/evaluate/edit`,
    method: 'post',
    data
  })
}

export function detail(id) {
  return http({
    url: `/company/evaluate/detail`,
    method: 'get',
    params: { id: id }
  })
}
